import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { push } from 'connected-react-router'
import {
  Text,
  Link,
  shuttleGray,
} from '@buffer-mono/legacy-bufferapp-components'
import { white, blue, gray } from '@bufferapp/ui/style/colors'
import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import dayjs from 'dayjs'

// Add tab keys in here to display the new flag on the link ex. ['overview']
// @ts-expect-error TS(7034) FIXME: Variable 'NEW_TABS' implicitly has type 'any[]' in... Remove this comment to see the full error message
const NEW_TABS = []

const InnerLink = styled.span`
  display: flex;
  justify-content: center;
  padding: 1rem 0.5rem;
  margin: 0 1.25rem 0 0.5rem;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  text-align: center;
  text-transform: capitalize;

  & > span {
    color: ${shuttleGray} !important;
  }

  & > span:hover {
    color: ${blue} !important;
  }

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'selected' does not exist on type 'Themed... Remove this comment to see the full error message
    props.selected &&
    css`
      border-color: ${blue};

      & > span {
        color: ${blue} !important;
      }
    `}
`

const LastUpdated = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
  margin: 0 1.25rem 0 auto;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  text-align: center;

  svg {
    color: ${gray};
    width: 14px;
    height: 14px;
    padding-right: 4px;
  }
`

const NewTag = styled.em`
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  background-color: #168eea;
  border-radius: 0.25rem;
  margin-left: 0.25rem;
  font-style: normal;
  color: white;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
const Item = ({ channel, tab, onClick, root }) => {
  const href = `/${channel.service}/${tab}/${channel.id}`
  const selected = root.includes(tab)
  // @ts-expect-error TS(7005) FIXME: Variable 'NEW_TABS' implicitly has an 'any[]' type... Remove this comment to see the full error message
  const isNew = NEW_TABS.includes(tab)
  return (
    <Link
      // @ts-expect-error
      href={href}
      // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
      onClick={(e) => {
        e.preventDefault()
        onClick(href)
      }}
      unstyled
    >
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <InnerLink selected={selected}>
        <Text
          weight="medium"
          color={selected ? undefined : 'shuttleGray'}
          size="mini"
        >
          {tab}
        </Text>
        {isNew && <NewTag>New</NewTag>}
      </InnerLink>
    </Link>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  background: ${white};
  padding: 0 0.5rem;
  border-bottom: 1px solid #b8b8b8 !important;
  border-radius: 3px 3px 0 0;
`

Item.propTypes = {
  root: PropTypes.string.isRequired,
  channel: PropTypes.shape({
    service: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  tab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
export const Subtabs = ({ channel, lastUpdated, ...props }) => {
  return (
    <>
      {channel && (
        <Container>
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'tab' implicitly has an 'any' type. */}
          {channel.supportedTabs.map((tab) => (
            // @ts-expect-error TS(2739) FIXME: Type '{ key: any; tab: any; channel: any; }' is mi... Remove this comment to see the full error message
            <Item tab={tab} channel={channel} {...props} key={tab} />
          ))}
          {lastUpdated && (
            <LastUpdated>
              <ClockIcon size="medium" />
              <Text weight="medium" color="outerSpaceLight" size="mini">
                <span
                  title={dayjs(lastUpdated).format('MMMM Do YYYY, HH:mm UTC')}
                >
                  Last updated {dayjs(lastUpdated).fromNow()}
                </span>
              </Text>
            </LastUpdated>
          )}
        </Container>
      )}
    </>
  )
}

Subtabs.propTypes = {
  channel: PropTypes.shape({
    supportedTabs: PropTypes.arrayOf(PropTypes.string),
  }),
  lastUpdated: PropTypes.string,
}

Subtabs.defaultProps = {
  channel: null,
  lastUpdated: null,
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'navSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
    channel: state.navSidebar.activeChannel,
    // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
    root: state.router.location.pathname,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    lastUpdated: state.profiles.lastUpdated[state.profiles.selectedProfileId],
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
    onClick: (path) => {
      dispatch(push(path))
    },
  }),
)(Subtabs)
